<template>
  <div class="project-detail">
    <section><TopInfo @refresh="loadData" :collection="true" :entity="entity" /></section>
    <section><Intro :entity="entity" /></section>
    <section><Attachment :entity="entity" /></section>
    <div class="footer">
      <!-- 入驻机构有抢单按钮 -->
      <mt-button
        :disabled="!!entity.offerStatus"
        :loading="offering"
        @click="onOffer"
        v-if="role === 'org' && entity.status < 4"
        type="primary"
        shape="round"
        >{{ entity.offerStatus ? '已抢单' : '抢单' }}</mt-button
      >
    </div>
  </div>
</template>
<script>
import comps from '../shared'
import { getDetail, getDetailForPublic, toOffer } from '@/api/project'
import { Toast, MessageBox } from 'mint-ui'
import { mapGetters } from 'vuex'
import { getCookie } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

export default {
  components: {
    ...comps
  },
  data() {
    const { projectId } = this.$route.params
    return {
      projectId,
      entity: {},
      offering: false
    }
  },
  computed: {
    ...mapGetters(['type', 'companyId']),
    role() {
      return this.type
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const token = getCookie(ACCESS_TOKEN)
      const __api = token ? getDetail : getDetailForPublic
      const { code, result } = await __api(this.projectId)
      if (code === 200) {
        this.entity = result
      }
    },

    // 抢单
    async onOffer() {
      try {
        this.offering = true
        const fd = new FormData()
        fd.append('projectId', this.projectId)
        fd.append('orgId', this.companyId)
        await toOffer(fd)
        Toast('抢单成功')
        this.$router.push('/admin/project')
      } catch (error) {
        if (error.response && error.response.data) {
          const { respMsg } = error.response.data
          if (respMsg) {
            if (respMsg === '信息费余额不足.') {
              MessageBox({
                title: '提示',
                message: '信息费不足，请充值',
                showCancelButton: true,
                confirmButtonText: '购买信息费',
                cancelButtonText: '取消'
              }).then(action => {
                if (action === 'confirm') {
                  this.$router.push('/admin/wallet-recharge')
                }
              })
            } else {
              Toast(respMsg)
            }
          }
        }
      } finally {
        this.offering = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
